// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#formHeader {
    color: rgb(124,255,255);
}

#formHeaderLight {
    color: black;
}

#formWrapper div label {
    color: rgb(124,255,255);
}

#formWrapperLight div label {
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/component/entryform/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB","sourcesContent":["#formHeader {\n    color: rgb(124,255,255);\n}\n\n#formHeaderLight {\n    color: black;\n}\n\n#formWrapper div label {\n    color: rgb(124,255,255);\n}\n\n#formWrapperLight div label {\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
