// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requestContainerResponseContainer {
    background-color: rgb(255,255,255);
    color: black;
    position: relative;
    height: 200px;
    width: 100%;
}

.requestContainerSegment {
    border-color: black;
    border-width: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/component/request/index.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".requestContainerResponseContainer {\n    background-color: rgb(255,255,255);\n    color: black;\n    position: relative;\n    height: 200px;\n    width: 100%;\n}\n\n.requestContainerSegment {\n    border-color: black;\n    border-width: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
