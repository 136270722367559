// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#userProfileImage {
    max-width: 300px;
    padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/component/dataitem/user/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":["#userProfileImage {\n    max-width: 300px;\n    padding-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
