// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoPageContainer {
    text-align: left;
    font-size: 20px;
    min-height: 100vh;
    max-width: 100vw;
}

.infoPageWrapper {
    margin-left: 200px;
    margin-right: 200px;

    @media only screen and (max-width: 600px) {
        margin-left: 0px;
        margin-right: 0px;
        font-size: 15px;
    }  
}

.reactMarkdown img {
    max-width: 70vw;
}

.reactMarkdownLight img {
    max-width: 70vw;
}

.reactMarkdown h1 {
    color: white;

    @media only screen and (max-width: 600px) {
        margin-left: 0px;
        margin-right: 0px;
        font-size: 15px;
    }  
}

.reactMarkdownLight h1 {
    color: black;
}

.reactMarkdown h2 {
    color: white;
}

.reactMarkdownLight h2 {
    color: black;
}

.reactMarkdown strong {
    color: white;
}

.reactMarkdownLight strong {
    color: black;
}

.reactMarkdown {
    color: rgb(160,255,255);
}

.reactMarkdownLight {
    color: rgb(0,96,0);
}
`, "",{"version":3,"sources":["webpack://./src/app/component/info/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;;IAEnB;QACI,gBAAgB;QAChB,iBAAiB;QACjB,eAAe;IACnB;AACJ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;;IAEZ;QACI,gBAAgB;QAChB,iBAAiB;QACjB,eAAe;IACnB;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".infoPageContainer {\n    text-align: left;\n    font-size: 20px;\n    min-height: 100vh;\n    max-width: 100vw;\n}\n\n.infoPageWrapper {\n    margin-left: 200px;\n    margin-right: 200px;\n\n    @media only screen and (max-width: 600px) {\n        margin-left: 0px;\n        margin-right: 0px;\n        font-size: 15px;\n    }  \n}\n\n.reactMarkdown img {\n    max-width: 70vw;\n}\n\n.reactMarkdownLight img {\n    max-width: 70vw;\n}\n\n.reactMarkdown h1 {\n    color: white;\n\n    @media only screen and (max-width: 600px) {\n        margin-left: 0px;\n        margin-right: 0px;\n        font-size: 15px;\n    }  \n}\n\n.reactMarkdownLight h1 {\n    color: black;\n}\n\n.reactMarkdown h2 {\n    color: white;\n}\n\n.reactMarkdownLight h2 {\n    color: black;\n}\n\n.reactMarkdown strong {\n    color: white;\n}\n\n.reactMarkdownLight strong {\n    color: black;\n}\n\n.reactMarkdown {\n    color: rgb(160,255,255);\n}\n\n.reactMarkdownLight {\n    color: rgb(0,96,0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
